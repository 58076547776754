@import "../../Variables";

.fluidContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    transition: opacity 0.5s ease 0.5s;
    opacity: 0;
    pointer-events: none;
    z-index: 1040;
    overflow: auto;
}

.active {
    opacity: 1;
    pointer-events: auto;
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: $light-gray;

    &:hover, &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
    }
}