@import "../Variables";

.shareContainer {
    background-color: $almost-white;
    font-weight: 400;
    position: relative;
}

.closeBtn {
    position: absolute;
    right: .25rem;
    top: .25rem;
}

.arrow_box {
    position: relative;
}
.arrow_box:after {
    top: 30%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $almost-white;
    border-width: 25px;
    margin-left: -20px;
}