.map {
    height: 400px;

    @media (max-width: 991px) {
        height: 300px;
    }


    @media (max-width: 320px) {
        height: 200px;
    }
}

.titleSection {
    position: relative;
    .editBtn {
        position: absolute;
        top: 0;
        right: 0;
    }
}