@import "../../Variables";

.sortableHelper {
    z-index: 1050;
}

.previewImage {
    height: 150px;
    width: 150px;
    object-fit: cover;
    cursor: grab;
}

.container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.imageItem {

    background-color: $white;
    border: 1px solid $almost-white;

    .imageContent {
        textarea {
            height: 150px;
            border: none;
            vertical-align: top;
            resize: none;
            &:focus {
                border: none;
            }
        }
    }
}


.dropzone {
    padding: 2rem;
    margin: 1rem;
    text-align: center;
    border: 2px dashed $light-gray;
}