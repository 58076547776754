@import "../../Variables";

.default {
    color: $black;
}

.light {
    color: #555555;
}

.inverted {
    color: $white;
}

.active {
    color: $active-blue;
}

.text {
    font-size: 1.25rem;
    line-height: 1.5;
    margin: 0;
}

.title {
    font-size: 1.75rem;
    line-height: 1.5;
}

.subtitle {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 1.4rem;
}

.sectionTitle {
    margin-top: 2.8rem;
}

.listItem {
    font-size: 1.5rem;
    line-height: 1.5;
}

.bold {
    font-weight: 400;
}

.smallText {
    font-size: .75rem;
}

.largeText {
    font-size: 1.25rem;
}

.link {
    text-decoration: underline !important;
    font-size: 1.4rem;
}
