.map {
    height: 400px;

    @media (max-width: 991px) {
        height: 300px;
    }


    @media (max-width: 320px) {
        height: 200px;
    }
}

.embed {
    width: 100%;
    border: none;
    height: 400px;

    @media (max-width: 991px) {
        height: 200px;
    }

    @media (max-width: 320px) {
        height: 130px;
    }
}