@import "../../Variables";

.block {
    position: fixed;
    z-index: 9990;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    .container {
        @media (min-width: 500px) {
            min-width: 400px;
        }

        @media (min-width: 768px) {
            min-width: 500px;
        }

        @media (min-width: 991px) {
            min-width: 650px;
        }
        padding: 1rem;
        border-radius: 4px;
        margin: auto;
        max-width: 300px;
        background-color: $white;
    }
}