@import "../../Variables";

.btn {
    height: 44px;
    min-width: 44px;
    outline: 0;
    line-height: 1.0;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400 !important;
    border-radius: 4px;

    .btnText {
        font-weight: 400;
        font-size: 20px;
        line-height: 1;
    }

    &.enabled:hover {
        cursor: pointer;
    }

    &.enabled:active {
        outline: 0;
    }

    &.enabled:focus {
        outline: 0;
    }
}

.btnPrimary {
    background-color: $active-blue;
    color: $white;

    &.enabled:active {
        opacity: 0.9;
    }
    .icon {
        fill: $white;
    }
    .btnText {
        color: white;
    }
}

.btnDefault {
    border: 1px solid $light-gray;
    background: none;
    color: $black;

    &.enabled:active {
        opacity: 0.8;
    }

    .icon {
        fill: $black;
    }
}

.btnNone {
    border: none;
    background-color: transparent;
    color: $black;

    &.enabled:active {
        opacity: 0.8;
    }

    .icon {
        fill: $black;
    }
}

.disabled {
    opacity: .5;
}