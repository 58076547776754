@import "../../Variables";

.invalid {
    border: 1px solid $red;
    color: $red;

    &:focus {
        outline: 1px solid $red;
    }
}

.valid {
    border: 1px solid $light-gray;

    &:focus {
        border: 1px solid $active-blue;
    }
}

.validationError {
    color: $red;
    font-size: 13px;
}

.sideIcon {
    position: absolute;
    right: 8px;
    top: 11px;
    background: transparent;
    border: 0;
    padding: 0;
    outline: none;
}

.inputIcon {
    font-size: 1.6rem;
}

.inputFieldWrapper {
    position: relative;
    width: 100%;
}

.inputFieldWrapper .inputField {
    width: 100%;
    outline: none;
    height: 44px;
}

.inputField {
    &.default {
        color: $black;
        background-color: $white;
    }
    font-size: 16px;
    cursor: text;
    padding-left: 10px;
    padding-top: 8px;

}

.inputFieldWrapper .inputField:invalid {
    box-shadow: none !important;
}

.floatingLabel {
    opacity: 0.7;

    &.default {
        color: $black;
    }
}

.inputFieldWrapper .floatingLabel {
    position: absolute;
    pointer-events: none;
    top: 11px;
    left: 10px;
    font-size: 16px;
    transition: 0.1s ease all;
}

.inputFieldWrapper input:focus ~ .floatingLabel,
.inputFieldWrapper input:not(:focus):valid ~ .floatingLabel,
.inputFieldWrapper input.moved:disabled ~ .floatingLabel {
    top: 2px;
    left: 10px;
    font-size: 11px;
    opacity: .7;
}