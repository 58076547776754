.wrapper {
    width: 100%;
    /* whatever width you want */
    display: inline-block;
    position: relative;
}

.wrapper16_9:after {
    padding-top: 56.25%;
    /* 16:9 ratio */
    display: block;
    content: '';
}

.wrapper16_10:after {
    padding-top: 62.5%;
    /* 16:9 ratio */
    display: block;
    content: '';
}

.main {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
}