@import "../Variables";

.addImagesButton {
    position: absolute;
    bottom: .5rem;
    left: .5rem;
}

.imageButton {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    transition: opacity .75s ease;
    position: absolute;
}

.mainImage {
    opacity: 0;
    transition: opacity 1s ease;
    position: absolute;
    &.selected {
        opacity: 1;
    }
}

.indicators {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: .5rem;
}

.imageContainer {
    position: relative;

    .imgMap {
        z-index: 99;
        right: .5rem;
        bottom: .5rem;
        width: 33%;
        opacity: 1;
        position: absolute;
        transition: opacity .75s ease;
    }
    .mapIcon {
        right: .5rem;
        bottom: .5rem;
        opacity: 0;
        transition: opacity .5s ease;
    }

    .btnPrev {
        left: .5rem;
        top: 50%;
        opacity: 0;
        transform: translateY(-50%);
    }

    .btnNext {
        right: .5rem;
        top: 50%;
        opacity: 0;
        transform: translateY(-50%);
    }

    .btnPlayPause {
        bottom: .5rem;
        left: .5rem;
        opacity: 0;
    }

    .galleryIcon {
        right: calc(1rem + 50px);
        bottom: .5rem;
        opacity: 0;
        transition: opacity .5s ease;
    }

    .btnEdit {
        bottom: .5rem;
        left: .5rem;
    }

    &:hover {
        .imageControls, .mapIcon, .btnPrev, .btnNext, .btnPlayPause, .galleryIcon {
            opacity: 1;
        }

        .imgMap {
            opacity: 0;
            pointer-events: none;
        }
    }
}