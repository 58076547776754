@import "../../Variables";

.gdprBlock {
    position: fixed;
    margin: 1rem;
    left: 0;
    bottom: 0;
    z-index: 9990;

    p, div {
        font-size: 12px !important;
        .gdprText {
            font-size: 14px !important;
        }
    }

    .gdprContainer {
        padding: 2rem;
        border-radius: 4px;
        margin: 1rem auto;
        max-width: 300px;
        background-color: $white;
        -webkit-box-shadow: 2px 2px 5px 0px rgba(102,102,102,0.6);
        -moz-box-shadow: 2px 2px 5px 0px rgba(102,102,102,0.6);
        box-shadow: 2px 2px 5px 0px rgba(102,102,102,0.6);
    }
}