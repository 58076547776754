.blogPost {
    line-height: 1.4;

    h1, h2, h3, h4, h5, h6 {
        line-height: 1.5;
        font-weight: 400;
    }

    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 24px;
    }

    h4 {
        font-size: 20px;
        font-style: italic;
    }

    h5 {
        font-size: 20px;
        font-style: italic;
        font-weight: 600;
    }

    p, li {
        font-size: 20px;
        line-height: 1.67;
    }

    ul {
        list-style-type: square;
    }
}
