.imgContainer {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin: .5rem;
    margin-right: 1rem;
    position: relative;

    .imgPlaceholder {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
    }
}

.tripHeadline {
    font-size: 1.75rem;
    line-height: 2.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.distance {
    font-size: 1.25rem;
    font-weight: 500;
}
