@import "../Variables";

.black {
    fill: #000;
}

.white {
    fill: #fff;
}

.active {
    fill: #328CF4;
}

.error {
    fill: #cc6600;
}

.light {
    fill: #555555;
}

.almostWhite {
    fill: $almost-white;
}
