@import "../../Variables";

.navContainer {
}
.navElement {
    position: relative;
    min-width: 100px;
    text-align: center;
    padding-top: .75rem;
    padding-bottom: .75rem;
    .navMarker {
        position: absolute;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        height: .2rem;
        background-color: $active-blue;
        opacity: 0;
        &.active {
            opacity: 1;
        }
    }
}

.controlElement {
    padding-top: .75rem;
    padding-bottom: .7rem;
}

.nav {
    border-bottom: 1px solid $almost-white;
}

.optionsMenu {
    overflow: hidden;
    max-height: 0;
    background-color: $almost-white;
    transition: max-height ease-in-out .5s;
    &.active {
        max-height: 1000px;
    }
}

.filterTextContainer {
    line-height: 1.8;
}