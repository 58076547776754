@import "../../Variables";

input.titleInput[type="text"] {
    font-size: 1.75rem;
    outline: none;
    border: 1px solid $almost-white;

    &:focus {
        border-color: $active-blue;
    }
}

textarea.descriptionInput {
    border: 1px solid $almost-white;
    outline: none;
    min-height: 10rem;
    padding: .5rem;
    resize: vertical;
    font-size: 1.25rem;
    font-weight: 300;

    &:focus {
        border-color: $active-blue;
    }
}

.descriptionBox {
    position: relative;

    .placeholder {
        position: absolute;
        padding: .5rem;
        color: $light-gray;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        pointer-events: none;
    }
}