@import "Variables";
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    height: 100%;
}

body {
    background-color: #ffffff;
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;

    @media (max-width: 320px) {
        font-size: 14px;
    }
}

h3 {
    font-weight: 400 !important;
}

.landing-header {
    background: linear-gradient(rgba(208, 222, 235, 0.5), rgba(145, 175, 189, 0.5)), url('https://assets.blueboatlog.com/background.jpg');
    background-blend-mode: overlay;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @media (max-width: 1023.98px) {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    @media (min-width: 1024px) {
        padding-top: 74px;
        padding-bottom: 74px;
    }
}

.landing-text {
    color: #000000;
}


/*navbar coloring*/
.navbar {
    background-color: #328CF4;
}


/*navbar coloring END*/
.image-loading {
    opacity: 0;
    transition: opacity 1.0s;
}

.image-loaded {
    opacity: 1;
}


.no-margin {
    margin: 0
}

a {
    text-decoration: none;
}

a.nodeco:link {
    color: inherit;
    text-decoration: none;
}

a.nodeco:visited {
    color: inherit;
    text-decoration: none;
}

a.nodeco:hover {
    color: #555555;
    text-decoration: none;
}

a.simple {
    color: inherit;
    text-decoration: none;
}

a.simple:link {
    color: inherit;
    text-decoration: none;
}

a.simple:visited {
    color: inherit;
    text-decoration: none;
}

a.simple:hover {
    color: #328CF4;
    text-decoration: none;
}


a.highlighted {
    text-decoration: none;
}
a.highlighted:hover {
    text-decoration: underline;
}

h1, h2, h3, h4 {
    font-family: Lato, sans-serif;
    font-weight: 300;
}

p, li {
    color: $font-lighter;
    font-size: 1.25rem;
    font-weight: 300;
}

p.content-text {
    line-height: 1.6 !important;
}

li.nav-item {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
}

.navbar-offset {
    padding-top: 56px;
}

.container-narrow {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 640px;
}

.global-load {
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    pointer-events: none;
    transition: opacity 1s ease-out;
    z-index: 99;
    &.hidden {
        opacity: 0;
    }
}

.image-descriptor {
    text-align: center;
    margin-bottom: 1rem;
    margin-top: .2rem;
}

.btn-primary {
    background-color: $active-blue;
    border-color: $active-blue;
}

.font-weight-bold {
    font-weight: 400 !important;
}

.border-bottom-light {
    border-bottom: 1px solid $almost-white;
}

button:focus {
    outline: none;
}

.MuiSpeedDialAction-staticTooltipLabel {
    white-space: nowrap;
}

.btn-close {
    position: absolute;
    right: .25rem;
    top: .25rem;
}

.btn-primary {
    background-color: #328CF4 !important;
    border-color: #328CF4 !important;
}

div.fabcontainer {
    pointer-events: none;
    z-index: 100;
    position: fixed;
    padding: 2em;
    left: calc(50% + 270px);
    bottom: 20px;
    transform: translate(-50%, 0);
}

.lato {
    font-family: Lato, sans-serif;
}
