.content {
    line-height: 1.67;

    h1 {
        font-size: 26px;
        font-weight: 400;
    }

    h2 {
        font-size: 22px;
        font-weight: 400;
    }

    h3 {
        font-size: 20px;
        font-weight: 400;
    }

    h4 {
        font-size: 20px;
        font-style: italic;
        font-weight: 400;
    }

    h5 {
        font-size: 20px;
        font-style: italic;
        font-weight: 300;
    }

    p {
        font-size: 20px;
    }

    ul {
        list-style-type: square;
    }
}