@import "../../Variables";

.background {
    background-color: $light-gray-translucent;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9900;
}