// color variables

$white: #fff;
$black: #000;
$font-lighter: #444;
$light-gray: #999999;
$light-gray-translucent: rgba(153,153,153,0.4);
$almost-white: #e9e9e9;
$almost-white-translucent: rgba(233,233,233,0.4);
$red: #cc6600;
$active-blue: #328CF4;
