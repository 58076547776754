.tableNumber {
    font-size: 2rem;
    font-weight: 300;
    margin: 0;
    line-height: 1;
}

.tableLegend {
    font-size: .8rem;
    font-weight: 300;
    margin: 0;

    line-height: 1;
}