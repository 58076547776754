@import "../../Variables";

.toggle {
    border: solid 1px $light-gray;
}

.toggleOption {
    color: $light-gray !important;
    background-color: $almost-white;
}

.inactiveLeft {
    background-color: $white;
    color: $black !important;
}

.activeLeft {
    background-color: $active-blue;
    color: $active-blue !important;
}

.inactiveRight {
    background-color: $light-gray;
    color: $light-gray !important;
}

.activeRight {
    background-color: $white;
    color: $black !important;
}

.disabled {
    opacity: .6;
}