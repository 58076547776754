.imageEditIcon {
    position: absolute;
    top: 0;
    right: 0;
}

.imageContainer {
    position: relative;
}

.textArea {
    textarea {
        min-height: 150px;
    }
}