@import "../../Variables";

.descriptionInput {
    padding: 0;
    margin: 0;
    color: $black;

    label {
        width: 100%;
    }

    textarea {
        width: 100%;
        padding: 5px 10px;
        line-height: 1.5;
        background-color: transparent;
        outline: none;
        border: 1px solid $almost-white;
    }

    textarea:focus {
        border: 1px solid $active-blue;
    }

}