.navBar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    min-height: 56px;
}

.link, .linkInverse {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    text-decoration: none;



}

.link {
    color: #000;
    &:link {
        color: #000;
        text-decoration: none;
    }

    &:visited {
        color: #000;
        text-decoration: none;
    }
}

.linkInverse {
    color: #fff;
    &:link {
        color: #fff;
        text-decoration: none;
    }

    &:visited {
        color: #fff;
        text-decoration: none;
    }
}

.menuButton {
    width: 56px;
    height: 56px;
    margin-left: -56px;
    display: flex;
    align-items: center;
    justify-content: center;
}